import React from "react";
import SEO from "../../components/seo/Seo";
import Layout from "../../components/Layout";
import { v4 } from "uuid";
import { StaticQuery, graphql } from "gatsby"; // to query for image data

const renderSEO = (
  {
    file: {
      childImageSharp: { resize: image },
    },
  },
  pathname
) => {
  return (
    <SEO
      title={`Links | Multiintolerant Na Und?`}
      description={`Übersicht aller Links rund um das Thema Fructosintoleranz, Histaminintoleranz, Lactosintoleranz und Multi-Intoleranz`}
      image={image}
      pathname={pathname}
    />
  );
};

const LinksPage = (props) => {
  const links = [
    {
      url: "https://www.olivers-glutenfreie-rezepte.com/",
      imgSrc:
        "https://le-cdn.website-editor.net/388f9f7fb7c648b8900a116676f0b245/dms3rep/multi/opt/Logo+Gruppe-480w.jpg",
      title: "Olivers glutenfreie Rezepte",
      description:
        "Einfache und geling sichere Rezepte auf Basis von Schärmehlen. Support für Anfänger und Fortgeschrittene über Facebookgruppe Olivers glutenfreie Rezepte.",
    },
  ];

  return (
    <Layout>
      <StaticQuery
        render={(data) => renderSEO(data, props.location.pathname)}
        query={graphql`
          query {
            file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        `}
      />
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: "6rem" }}
            >
              <h1 className="title is-size-2 is-bold-light">Links</h1>
            </div>
          </div>
          <div className="columns is-multiline">
            {links.map((link) => (
              <div key={v4()} className="is-parent column is-6">
                <CardComponent {...link} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

const CardComponent = ({ imgSrc, title, description, url }) => {
  return (
    <div
      className="card box"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="card-image">
        <a href={url} rel="noopener noreferrer">
          <figure className="is-4by3 is-256x256">
            <img src={imgSrc} alt={title} />
          </figure>
        </a>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <a href={url} rel="noopener noreferrer">
              <p className="title is-4" style={{ overflow: "hidden" }}>
                {title}
              </p>
            </a>
          </div>
        </div>

        <div className="content" style={{ marginTop: "20px" }}>
          {description}
        </div>
      </div>
    </div>
  );
};

export default LinksPage;
